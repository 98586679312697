import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUi from "element-ui";
import "./styles/element-variables.scss";
import "./styles/reset.css";
import "./styles/index.scss";
// 全局指令
import "@/directives/action.js";
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

// 添加全局filter
import filters from "./filters/index";
Object.keys(filters).map(v => {
  Vue.filter(v, filters[v]);
});

//权限控制
import "./permission";
Vue.config.productionTip = false;
Vue.use(ElementUi);
Vue.use(VueViewer, {name: 'vuer',defaultOptions: {zIndex: 9999}})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

import request from "./request";

export function login(params) {
  return request({
    url: "/auth/login",
    method: "post",
    params,
  });
}

//退出
// export function logout(params) {
//   return request({
//     url: "/sass-api/sass/user/logout",
//     method: "get",
//     params
//   });
// }

export function userInfo() {
  return request({
    url: "/auth/userInfo",
    method: "get",
  });
}

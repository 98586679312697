<template>
  <el-menu
    mode="vertical"
    class="el-menu-vertical-demo"
    :default-active="$route.meta.activePath"
    background-color="white"
    router
  >
    <sidebar-item
      v-if="permission_routers"
      :routes="permission_routers"
    ></sidebar-item>
  </el-menu>
</template>

<script>
import SidebarItem from "./SidebarItem";
import constantRoutes from "@/router/constantRoutes";

export default {
  data() {
    return {};
  },
  components: { SidebarItem },
  computed: {
    permission_routers() {
      console.log(123);
      if (this.$store.state.menu.menus) {
        console.log(
          this.$store.state.menu.menus,
          "this.$store.state.menu.menus"
        );
        return constantRoutes.concat(this.$store.state.menu.menus);
      }
      return constantRoutes;
    },
  },
};
</script>
<style lang="scss" scoped></style>

<template>
  <div class="home">
    <el-container class="home-container">
      <el-header height="64">
        <HeadNav></HeadNav>
      </el-header>
      <el-container>
        <el-aside width="210">
          <AsideMenu></AsideMenu>
        </el-aside>
        <el-main>
          <div class="router-content">
            <!-- <Breadcrumb></Breadcrumb> -->
            <div class="router-wrapper">
              <div class="router-view_content">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// import Breadcrumb from "./components/Breadcrumb.vue";
import HeadNav from "./components/HeadNav.vue";
import AsideMenu from "./components/AsideMenu.vue";
export default {
  name: "homePage",
  components: {
    HeadNav,
    AsideMenu,
    // Breadcrumb,
  },
  data() {
    return {
      name: "home",
    };
  },
};
</script>
<style lang="scss" scoped>
.home,
.home-container {
  height: 100%;
}
.el-main {
  padding: 0;
  &::-webkit-scrollbar-thumb {
    width: 16px;
    height: 16px;
    background-color: #f5f5f5;
  }
}
.el-container {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}
.el-aside {
  margin-top: 41px;
  &::-webkit-scrollbar {
    display: none;
  }
  padding-bottom: 60px;
}
.router-view_title {
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #303133;
  line-height: 25px;
  margin-bottom: 24px;
}
.router-content {
  box-sizing: border-box;
  background: #f0f2f5;
  padding: 40px 24px 24px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.router-wrapper {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.router-view_content {
  flex: 1;
  position: relative;
  min-height: 0;
}
</style>

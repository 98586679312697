import axios from "axios";
import storageUtil from "@/utils/storageUtil";
import router, { resetRouter } from "@/router";
import { Message, Loading } from "element-ui";
import store from "@/store";

// definition request loading
let loading;
// start request loading
const startLoading = () => {
  loading = Loading.service({
    lock: true,
    text: "加载中..."
  });
};
// stop request loading
const endLoading = () => {
  loading.close();
};

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: "/sass-api/sass-api/sass",
  timeout: 300000 // 请求超时时间
});
// 异常拦截处理器
const errorHandler = error => {
  if (error.response) {
    if (error.response.status === 401 || error.response.status === 0) {
      // 清除本地存储 token
      storageUtil.clearStorage();
      Message({
        message: "未授权请重新登录",
        type: "error"
      });
      // 清除缓存
      resetRouter();
      router.push("/")
    } else {
      Message({
        message: error.response.status,
        type: "error"
      });
    }
  }
  // stop request loading
  endLoading();
  return Promise.reject(error);
};

// request interceptor
request.interceptors.request.use(config => {
  // start request loading
  startLoading();
  const token = storageUtil.getStorageItem("token");
  config.headers["CCT-TOKEN"] = token;
  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use(response => {
  // stop request loading
  endLoading();
  if (response.data.code === 0) {
    if (response.data.message) {
      Message({
        message: response.data.message,
        type: "error"
      });
    }
    return Promise.reject(response.data);
  } else if (response.data.code === 3 || response.data.code === 4) {
    if (response.data.code === 3) {
      Message({
        message: "请先登录",
        type: "error"
      });
    } else if (response.data.code === 4) {
      Message({
        message: "登录已过期，请重新登录",
        type: "error"
      });
    }
    // 清除本地存储 token
    const platformId = storageUtil.getStorageItem("PLATFORMID");
    // 清除缓存
    storageUtil.clearStorage();
    store.commit("SET_MENUS", null);
    store.commit("SET_USER", null);
    resetRouter();
    if (platformId) {
      router.replace({ name: "PlatLogin", params: { platformId } });
    } else {
      router.replace({ name: "login" });
    }
    return Promise.reject(response.data);
  }
  return response.data;
}, errorHandler);

export default request;

import Vue from "vue";
import Router from "vue-router";
import routes from "./constantRoutes";

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err);
// };

Vue.use(Router);

const createRouter = () =>
  new Router({
    mode: "history",
    routes: routes,
  });

const router = createRouter();

router.beforeEach((to, form, next) => {
  if (to.name == "RoleTPColumnInfo") {
    to.meta.title = `栏目详情(${to.query.name})`;
  }
  next();
});

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;

function companyFundingTypeEnum(type) {
  if (type === "ADMINISTRATOR") {
    return "管理人";
  } else {
    return "融资方";
  }
}

function companyType(type) {
  console.log(type);
  if (type === "TRUST") {
    return "信托";
  } else if (type === "ASSET_MANAGEMENT") {
    return "资管";
  } else if (type === "PRIVATE_PLACEMENT") {
    return "私募";
  }
}

function companyType2(type) {
  console.log(type);
  if (type === "TRUST") {
    return "信托";
  } else if (type === "MANAGEMENT") {
    return "资管";
  } else if (type === "PRIVATE") {
    return "私募";
  }
}

function getLabel(key) {
  let Dictionaries = {
    TRUST: "信托",
    PRIVATE: "私募",
    MANAGEMENT: "资管",
    BOND: "证券",
    STOCK_RIGHT: "股权",
    INCOME: "固收",
    POLITICAL: "政信",
    REAL: "房地产",
    INDUSTRIAL: "工商企业",
    FUND: "资金池",
    EQUITY: "股权",
    INVESTMENT: "城投",
    OTHER: "其他",
  };
  return Dictionaries[key];
}

function getEventLabel(key) {
  let arr = ["", "立案", "一审", "二审", "终审"];
  return arr[Number(key)] || key;
}

function origin(type) {
  let origins = {
    CREATE_SELF: "自建",
    EXTERNAL: "外采",
    PLATFORM: "平台创建",
  };
  return origins[type];
}

// 合同状态
const filterContractStatus = (val) => {
  const status = [
    "待审核",
    "已驳回",
    "已通过",
    "已寄出",
    "已签收",
    "已回收",
    "",
    "",
    "",
    "已丢失",
  ];
  return status[val];
};

const filterSettleStatus = (val) => {
  const status = ["待结算", "已结算"];
  return status[val];
};

// 年月日
const filterDate = (time) => {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const ri = date.getDate();

  return `${year}年${month}月${ri}日`;
};

const filterCategory = (type) => {
  let category = {
    ADMINISTRATOR: "机构商户",
    THIRD_PARTY_COMPANIES: "独立理财师、家族办公室商户",
  };
  return category[type];
};

const filterJiGou = (type) => {
  let jigou = {
    PRIVATE: "私募",
    TRUST: "信托",
    MANAGEMENT: "资管",
    THIRD: "第三方理财",
    HOME_OFFICE: "家族办公室",
  };
  return jigou[type];
};

const filterBusinessDirection = (type) => {
  let business = {
    MANAGEMENT: "资管",
    PRIVATE: "私募",
    COMPREHENSIVE: "综合",
    LAW: "法律",
    TAX: "税务",
    IMMIGRANT: "移民",
    OVERSEAS: "海外资产",
    OTHER: "其他",
  };
  return business[type];
};

export default {
  companyFundingTypeEnum: companyFundingTypeEnum,
  companyType: companyType,
  getLabel,
  getEventLabel,
  origin,
  companyType2,
  filterContractStatus,
  filterDate,
  filterSettleStatus,
  filterCategory,
  filterJiGou,
  filterBusinessDirection,
};

// 格式化菜单
export const formatMenus = (menus) => {
  const routes = [];
  menus.forEach((menu) => {
    let route = null;
    route = {
      name: menu.name,
      path: menu.path,
      component: (resolve) => require([`@/${menu.url}`], resolve),
      redirect: menu.redirect,
      hidden: menu.menuShow === "N",
      meta: {
        icon: menu.icon,
        title: menu.title,
        permission: menu.buttonTypes,
        activePath: menu.activePath,
      },
    };
    if (menu.childMenu && menu.childMenu.length > 0) {
      addChildMenus(route, menu.childMenu);
    }

    routes.push(route);
  });

  return routes;
};

const addChildMenus = (route, menus) => {
  const childRoutes = [];
  menus.forEach((menu) => {
    const route = {
      name: menu.name,
      path: menu.path,
      component: (resolve) => require([`@/${menu.url}`], resolve),
      redirect: menu.redirect,
      hidden: menu.menuShow === "N",
      meta: {
        icon: menu.icon,
        title: menu.title,
        permission: menu.buttonTypes,
        activePath: menu.activePath,
      },
      children: [],
    };
    if (menu.childMenu && menu.childMenu.length > 0) {
      addChildMenus(route, menu.childMenu);
    }
    childRoutes.push(route);
  });

  route.children = childRoutes;
};

import { getUserMenuList } from "@/api/user";
import { formatMenus } from "@/utils/generate_routes";

export default {
  state: {
    menus: null,
  },
  getters: {},
  mutations: {
    SET_MENUS: (state, payload) => {
      state.menus = payload;
    },
  },
  actions: {
    getUserMenuLists({ commit }, { userId }) {
      return getUserMenuList(userId).then((res) => {
        const menus = formatMenus(res.results);
        commit("SET_MENUS", menus);
        return menus;
      });
    },
  },
};

import router, { resetRouter } from "@/router";
import { login } from "@/api/login";
import storageUtil from "@/utils/storageUtil";

const user = {
  state: {
    userInfo: null,
  },

  getters: {},

  mutations: {
    SET_USER: (state, payload) => {
      state.userInfo = payload;
    },
    SET_LOGOUT: () => {
      storageUtil.clearStorage();
    },
  },

  actions: {
    // 登录
    login({ commit }, payload) {
      return login(payload).then((res) => {
        console.log(res, "res");
        storageUtil.setStorageItem("token", res.results);
        commit("SET_USER", res.results);
        return res;
      });
    },
    // 登出
    logout({ commit }) {
      commit("SET_LOGOUT");
      commit("SET_USER", null);
      resetRouter();
      router.replace({ name: "login" });
      commit("SET_MENUS", null);
    },
  },
};

export default user;

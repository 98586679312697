<template>
  <div class="header-wrap">
    <div class="platform-name">
      <img src="../../assets/images/logo.png" alt="logo" height="18" />
    </div>
    <div class="header-right-wrapper">
      <div class="header-cover">
        <i
          class="el-icon-bell pointer"
          style="margin-right: 20px"
          @click="toChatPage"
        ></i>
        <el-dropdown @command="handleCommand">
          <div class="head-name">{{ name ? name : "" }}</div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="password">修改密码</el-dropdown-item>
            <el-dropdown-item command="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <el-dialog
      title="修改密码"
      :visible.sync="showPasswordDialog"
      width="520px"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="passwordForm">
        <el-form-item label="原密码" prop="oldPassword">
          <el-input v-model="form.oldPassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="form.newPassword"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="newPassword2">
          <el-input
            v-model="form.newPassword2"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showPasswordDialog = false">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import storageUtil from "@/utils/storageUtil";
import { updatePassword } from "@/api/user";

export default {
  name: "head-nav",
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.form.newPassword2 !== "") {
          this.$refs.passwordForm.validateField("newPassword2");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.form.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      userInfo: {},
      userMerchant: "",
      showPasswordDialog: false,
      form: {
        oldPassword: "",
        newPassword: "",
        newPassword2: "",
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        newPassword2: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  created() {
    this.userInfo = storageUtil.getStorageItem("userInfo");
    this.userMerchant = this.userInfo.merchantId;
    this.avtar = this.userInfo.imageUrl;
    this.name = this.userInfo.name;
  },
  methods: {
    async handleCommand(command) {
      if (command === "password") {
        this.showPasswordDialog = true;
      } else if (command === "logout") {
        this.$store.dispatch("logout");
      }
    },
    toChatPage() {
      let chatUserId = `${this.userInfo.merchantId}-${this.userInfo.merchantName}-${this.userInfo.name}`;
      window.open(`https://im.jiadingtianming.com/#/home?userId=${chatUserId}`);
    },
    sure() {
      this.$refs.passwordForm.validate(async (valid) => {
        if (valid) {
          try {
            await updatePassword(this.form);
            this.$notify({
              title: "成功",
              message: "密码修改成功",
              type: "success",
            });
            this.showPasswordDialog = false;
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.header-wrap {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 6px 0px rgba(132, 136, 150, 0.1);
  padding: 0 24px 0 36px;
  .platform-name {
    width: 156px;
    height: 18px;
    font-size: 14px;
    font-family: AlibabaPuHuiTi_2_115_Black;
    color: #303133;
  }
  .breadcrumb-wrapper {
    display: flex;
    align-items: center;

    .collapse-icon {
      cursor: pointer;
      margin-right: 20px;
    }
  }
  .header-right-wrapper {
    display: flex;
    // margin-top: 12px;
    .head-name {
      cursor: pointer;
    }
  }
}
</style>

<template>
  <div class="menu-wrapper">
    <!-- eslint-disable-next-line -->
    <template v-for="(item, index) in routes" v-if="!item.hidden" >
      <!-- 父菜单只有一个非hidden的子菜单就直接显示子菜单 -->
      <el-menu-item
        v-if="hasOneShowingChildren(item.children)"
        :index="baseUrl + item.path + '/' + item.children[0].path"
        :key="item.index"
      >
        <template v-if="item.meta.icon && item.meta.icon.startsWith('el-icon')">
          <i :class="item.meta.icon"></i>
        </template>
        <template v-else-if="item.meta.icon">
          <img class="icon active-icon" :src="item.meta.icon" alt="" />
        </template>
        <span slot="title">{{ item.children[0].meta.title }}</span>
      </el-menu-item>
      <!-- 父菜单全是hidden的子菜单就直接显示父菜单 -->
      <el-menu-item
        v-else-if="!hasShowingChildren(item.children)"
        :index="baseUrl + item.path"
        :key="item.index"
      >
        <template v-if="item.meta.icon && item.meta.icon.startsWith('el-icon')">
          <i :class="item.meta.icon"></i>
        </template>
        <template v-else-if="item.meta.icon">
          <img class="icon active-icon" :src="item.meta.icon" alt="" />
        </template>
        <span slot="title">{{ item.meta.title }}</span>
      </el-menu-item>

      <!-- 父菜单有不止一个非hidden的子菜单 -->
      <el-submenu
        v-else-if="hasShowingChildren(item.children)"
        :index="baseUrl + item.path"
        :key="item.index"
      >
        <!-- 父菜单的icon和title -->
        <template slot="title">
          <template
            v-if="item.meta.icon && item.meta.icon.startsWith('el-icon')"
          >
            <i :class="item.meta.icon"></i>
          </template>
          <template v-else-if="item.meta.icon">
            <img class="icon active-icon" :src="item.meta.icon" alt="" />
          </template>
          <span slot="title">{{ item.meta.title }}</span>
        </template>

        <!-- 子菜单 -->
        <!-- eslint-disable-next-line -->
        <template v-if="!child.hidden" v-for="child in item.children">
          <sidebar-item
            v-if="child.children && child.children.length > 0"
            :routes="[child]"
            :baseUrl="item.path + '/'"
            :key="child.name"
          ></sidebar-item>
          <el-menu-item
            v-else
            :index="baseUrl + item.path + '/' + child.path"
            :key="child.name"
          >
            <template
              v-if="child.meta.icon && child.meta.icon.startsWith('el-icon')"
            >
              <i :class="child.meta.icon"></i>
            </template>
            <template v-else-if="child.meta.icon">
              <img class="icon active-icon" :src="child.meta.icon" alt="" />
            </template>
            <span slot="title">{{ child.meta.title }}</span>
          </el-menu-item>
        </template>
      </el-submenu>
    </template>
  </div>
</template>

<script>
export default {
  name: "SidebarItem",
  props: {
    routes: {
      type: Array,
    },
    baseUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      user: null,
    };
  },
  methods: {
    hasOneShowingChildren(children) {
      const showingChildren =
        children &&
        children.filter((item) => {
          return !item.hidden;
        });
      return showingChildren && showingChildren.length === 1;
    },
    hasShowingChildren(children) {
      const showingChildren =
        children &&
        children.filter((item) => {
          return !item.hidden;
        });
      return showingChildren && showingChildren.length > 0;
    },
  },
  created() {
    const user = localStorage.getItem("userInfo");
    if (user) {
      this.user = JSON.parse(user);
    }
  },
};
</script>

<style lang="scss" scoped>
.icon-menu {
  margin-right: 10px;
}
.icon-box {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 6px;
  overflow: hidden;
}
.icon {
  width: 14px;
  height: 14px;
  overflow: hidden;
  margin-right: 6px;
  vertical-align: middle;
  align-self: center;
}

.el-menu-item.is-active {
  .active-icon {
    transform: translateX(-100px);
    filter: drop-shadow(#254ef4 100px 0px 0px);
  }
}
</style>

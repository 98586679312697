import storage from "store";
var expirePlugin = require("store/plugins/expire");

// 过期插件
storage.addPlugin(expirePlugin);

/**
 * @Author: randy
 * @description: 保存数据到localStorage
 * @param {key 键，value 值}
 * @return: undefined
 */
const setStorageItem = (key, value) => {
  storage.set(key, value);
};

/**
 * @Author: randy
 * @description: 保存数据到localStorage 带上过期时间 单位毫秒
 * @param {key 键，value 值，duration 存储时间 毫秒}
 * @return: undefined
 */
const setExpiredStorageItem = (key, value, duration) => {
  storage.set(key, value, new Date().getTime() + duration);
};

/**
 * @Author: randy
 * @description: 从localStorage获取数据
 * @param {key 键}
 * @return: value
 */
const getStorageItem = (key) => {
  return storage.get(key);
};
/**
 * @Author: randy
 * @description: 从localStorage移除数据
 * @param {key 键}
 * @return: undefined
 */
const removeStorageItem = (key) => {
  storage.remove(key);
};
/**
 * @Author: randy
 * @description: 清空localStorage
 * @param {}
 * @return: undefined
 */
const clearStorage = () => {
  storage.clearAll();
};

export default {
  setStorageItem,
  setExpiredStorageItem,
  getStorageItem,
  removeStorageItem,
  clearStorage,
};

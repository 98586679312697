import Layout from "@/layout/Home.vue";
let constantRoutes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/login/Login.vue"),
    hidden: true,
  },
  {
    path: "/index",
    name: "LayoutIndex",
    component: Layout,
    hidden: false,
    meta: { title: "首页", icon: "el-icon-s-home" },
    redirect: "/index/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("../views/HomeView.vue"),
        hidden: false,
        meta: {
          title: "首页",
          icon: "el-icon-s-home",
          activePath: "/index/home",
        },
      },

      // {
      //   path: "contract",
      //   name: "Contract",
      //   component: () => import("../views/contract/Index.vue"),
      //   hidden: false,
      //   meta: {
      //     title: "合同",
      //     activePath: "/index/contract",
      //   },
      // },
      // {
      //   path: "cost",
      //   name: "Cost",
      //   component: () => import("../views/cost/Index.vue"),
      //   hidden: false,
      //   meta: {
      //     title: "费用",
      //     activePath: "/index/cost",
      //   },
      // },
    ],
  }
];
export default constantRoutes;

import router from "./router";
import store from "@/store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import storageUtil from "@/utils/storageUtil";
NProgress.configure({ showSpinner: false });

const whiteLists = ["/"];

router.beforeEach((to, from, next) => {
  NProgress.start();

  const token = storageUtil.getStorageItem("token");
  if (token) {
    if (to.path != "/") {
      if (!store.state.menu.menus) {
        const userInfo = storageUtil.getStorageItem("userInfo");
        if (userInfo) {
          store
            .dispatch("getUserMenuLists", { userId: userInfo.id })
            .then((res) => {
              // 动态添加可访问路由表
              router.addRoutes(res);
              next({ ...to, replace: true });
            });
        } else {
          store.dispatch("logout");
        }
      } else {
        next();
      }
    } else {
      next({ name: "home" });
    }
  } else {
    if (whiteLists.includes(to.path)) {
      next();
    } else {
      next("/");
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});

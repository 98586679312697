import request from "./request";

//获取用户列表
export function getUserList(params) {
  return request({
    url: "/sass-api/user/userList",
    method: "get",
    params,
  });
}

//启用禁用用户
export function changeUserStatus(data) {
  return request({
    url: `/auth/changeUserStatus/${data}`,
    method: "post",
  });
}

//获取用户详情
export function getUserDetail(data) {
  return request({
    url: `/sass-api/user/queryUser/${data}`,
    method: "get",
  });
}

//添加用户
export function addUser(data) {
  return request({
    url: "/user/addUser",
    method: "post",
    data,
  });
}
//添加用户
export function updateUser(data) {
  return request({
    url: "/user/updateUser",
    method: "post",
    data,
  });
}

//编辑用户
export function editUser(data) {
  return request({
    url: "/sass-api/user/editUser",
    method: "post",
    data,
  });
}

// 上传用户学分证书
export function uploadScorePic(params) {
  return request({
    url: "/user/uploadScorePic",
    method: "post",
    data: params,
  });
}

//根据条件查用户
export function getUsersByCondition(data) {
  return request({
    url: "/sass-api/sys/data/selectUserByCondition",
    method: "post",
    data,
  });
}
//登录
export function login(data) {
  return request({
    url: "/sass-api/user/expert/login",
    method: "post",
    data,
  });
}
//用户详情
export function userInfo() {
  return request({
    url: "/sass-api/user/userInfo",
    method: "get",
  });
}
//退出
export function logout(params) {
  return request({
    url: "/sass-api/user/logout",
    method: "get",
    params,
  });
}

// 获取短信验证
export function sendCode(params) {
  return request({
    url: "/sass-api/user/sendCode",
    method: "get",
    params,
  });
}

// 通过新密码修改老密码
export function updatePasswordByNew(data) {
  return request({
    url: "/sass-api/user/updatePasswordByNew",
    data,
    method: "post",
  });
}

// 查看用户列表
export function userPage(params) {
  return request({
    url: "/user/list/index",
    method: "get",
    params,
  });
}

// 用户列表
export function userLists(params) {
  return request({
    url: "/user/list",
    method: "get",
    params,
  });
}
export function listByRole(params) {
  return request({
    url: "/user/listByRole",
    method: "get",
    params,
  });
}

// 设置用户权限
export function setUserAuthority(data) {
  return request({
    url: "/auth/setUserRole",
    data,
    method: "post",
  });
}

// 获取角色列表
export function roleListNoPage(params) {
  return request({
    url: "/auth/roleListNoPage",
    method: "get",
    params,
  });
}

// 获取角色列表
export function getUserMenuList(data) {
  return request({
    url: `/auth/getUserMenu/${data}`,
    method: "get",
  });
}

// 获取学员信息和问卷提交时间
export function getDetailByQuestionId(params) {
  return request({
    url: `/sass-api/user/student/getDetailByQuestionId`,
    method: "get",
    params,
  });
}

export function updatePassword(data) {
  return request({
    url: `/auth/updateUserPassword`,
    method: "post",
    data,
  });
}
